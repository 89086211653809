<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3 class="float-left">Editar Atributo</h3>
      </CCardHeader>
      <CCardBody>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="contact-form" method="post" v-on:submit.prevent="handleSubmit(actualizar)">
            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Atributo:
                  </label>
                  <validation-provider
                      name="Atributo"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <input
                        type="text"
                        v-model="registro.atributo"
                        class="form-control"
                    />
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>
            <!--  <CRow>
               <CCol sm="12">
                 <div class="form-group">
                   <label>
                     Textura:
                   </label>
                   <validation-provider
                     name="Textura"
                     :rules="{ required: true }"
                     :customMessages="{
                       required: 'Es un campo requerido'
                     }"
                     v-slot="validationContext"
                   >
                     <input
                       type="text"
                       v-model="registro.textura"
                       class="form-control"
                     />
                     <span style="color: red; font-size: small">
                       {{ validationContext.errors[0] }}
                     </span>
                   </validation-provider>
                 </div>
               </CCol>
             </CRow>
             <CRow>
               <CCol sm="12">
                 <div class="form-group">
                   <label>
                     Color:
                   </label>
                   <validation-provider
                     name="Color"
                     :rules="{ required: true }"
                     :customMessages="{
                       required: 'Es un campo requerido'
                     }"
                     v-slot="validationContext"
                   >
                     <input
                       type="text"
                       v-model="registro.color"
                       class="form-control"
                     />
                     <span style="color: red; font-size: small">
                       {{ validationContext.errors[0] }}
                     </span>
                   </validation-provider>
                 </div>
               </CCol>
             </CRow>
             <CRow>
               <CCol sm="12">
                 <div class="form-group">
                   <label>
                     Orden:
                   </label>
                   <validation-provider
                     name="Orden"
                     :rules="{ required: true }"
                     :customMessages="{
                       required: 'Es un campo requerido'
                     }"
                     v-slot="validationContext"
                   >
                     <input
                       type="text"
                       v-model="registro.orden"
                       class="form-control"
                     />
                     <span style="color: red; font-size: small">
                       {{ validationContext.errors[0] }}
                     </span>
                   </validation-provider>
                 </div>
               </CCol>
             </CRow> -->


            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Idioma:
                  </label>
                  <validation-provider
                      name="Idioma"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <selectIdioma @ActualizandoId="setIdioma" :idiomaId="idiomaId"/>
                    <input type="hidden" v-model="registro.eco_idioma_id"/>
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="12">
                <div class="form-group">
                  <label>
                    Atributo grupo:
                  </label>
                  <validation-provider
                      name="Atributo grupo"
                      :rules="{ required: true }"
                      :customMessages="{
                      required: 'Es un campo requerido'
                    }"
                      v-slot="validationContext"
                  >
                    <selectAtributoGrupo @ActualizandoId="setAtributoGrupo" :atributoGrupoId="atributoGrupoId"/>
                    <input type="hidden" v-model="registro.eco_atributo_grupo_id"/>
                    <span style="color: red; font-size: small">
                      {{ validationContext.errors[0] }}
                    </span>
                  </validation-provider>
                </div>
              </CCol>
            </CRow>

            <div class="form-group">
              <button type="submit" class="btn btn-primary">
                <span class="cil-save btn-icon mr-2"></span>
                Actualizar
              </button>
              <router-link to="/Ecoatributos/List">
                <CButton class="ml-2" color="secondary">
                  <span class="cil-x-circle btn-icon mr-2"></span>
                  Cancelar
                </CButton>
              </router-link>
            </div>
          </form>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import httpService from "@/services/httpService"
import selectIdioma from '@/components/SelectIdioma.vue'
import selectAtributoGrupo from '@/components/SelectAtributoGrupo.vue'

export default {
  components: {
    selectIdioma,
    selectAtributoGrupo,
  },
  data() {
    return {
      urlImage: process.env.VUE_APP_STORAGE,
      id: '',
      registro: {},
      atributoGrupoId: '',
      idiomaId: '',
    }
  },

  methods: {
    async getRegistro() {

      this.id = this.$route.params.id
      if (this.id !== '0') {
        let url = 'eco-atributos/' + this.id
        let response = await httpService.get(url)
        let respuesta = response.data
        this.registro = respuesta.registros[0]
        this.atributoGrupoId = this.registro.eco_atributo_grupo_id.toString()
        this.idiomaId = this.registro.eco_idioma_id.toString()
      }
    },
    setIdioma(value) {
      this.registro.eco_idioma_id = value;
    },
    setAtributoGrupo(value) {
      this.registro.eco_atributo_grupo_id = value;
    },
    async actualizar() {
      try {

        let url = 'eco-atributos/' + this.id
        let response = await httpService.put(url, this.registro)
        this.$vToastify.success('Operación realizada con éxito', 'Éxito')
        this.$router.back()
      } catch (error) {
        console.log(error)
      }
    }
  },

  mounted() {
    this.getRegistro()
  }
}
</script>