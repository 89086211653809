var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_c('h3',{staticClass:"float-left"},[_vm._v("Editar Atributo")])]),_c('CCardBody',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"contact-form",attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.actualizar)}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Atributo: ")]),_c('validation-provider',{attrs:{"name":"Atributo","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.atributo),expression:"registro.atributo"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.registro.atributo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "atributo", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Idioma: ")]),_c('validation-provider',{attrs:{"name":"Idioma","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('selectIdioma',{attrs:{"idiomaId":_vm.idiomaId},on:{"ActualizandoId":_vm.setIdioma}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.eco_idioma_id),expression:"registro.eco_idioma_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.registro.eco_idioma_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "eco_idioma_id", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(" Atributo grupo: ")]),_c('validation-provider',{attrs:{"name":"Atributo grupo","rules":{ required: true },"customMessages":{
                    required: 'Es un campo requerido'
                  }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('selectAtributoGrupo',{attrs:{"atributoGrupoId":_vm.atributoGrupoId},on:{"ActualizandoId":_vm.setAtributoGrupo}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.registro.eco_atributo_grupo_id),expression:"registro.eco_atributo_grupo_id"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.registro.eco_atributo_grupo_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.registro, "eco_atributo_grupo_id", $event.target.value)}}}),_c('span',{staticStyle:{"color":"red","font-size":"small"}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])]}}],null,true)})],1)])],1),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_c('span',{staticClass:"cil-save btn-icon mr-2"}),_vm._v(" Actualizar ")]),_c('router-link',{attrs:{"to":"/Ecoatributos/List"}},[_c('CButton',{staticClass:"ml-2",attrs:{"color":"secondary"}},[_c('span',{staticClass:"cil-x-circle btn-icon mr-2"}),_vm._v(" Cancelar ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }